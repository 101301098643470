import React, { useCallback, useEffect, useState } from "react";
import {
  Text,
  Flex,
  Heading,
  Card,
  Button,
  CardBody,
  CardFooter,
  ButtonGroup,
  useDisclosure,
  Icon,
  Tbody,
  Thead,
  Tr,
  Th,
  Table,
  useToast,
  Td,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { FaInfoCircle } from "react-icons/fa";
import {
  btcToFiat,
  WalletMeta,
  UserTxsResponse,
  UserTxsRequest,
  PaginatedSolowalletTxsResponse,
  SolowalletTx,
  User,
  TransactionStatus,
  PaginatedRequest,
  TransactionType,
} from "@bitsacco/types";
import {
  useAuth,
  useQuote,
  useAppState,
  FeatureTease,
  PersonalSavingsInfoModal,
  PersonalSavingsDepositModal,
  PersonalSavingsWithdrawModal,
  getTxStatusBadge,
  getTxTypeBadge,
  TransactionTarget,
  PersonalSavingsContinueModal,
} from "../components";
import {
  copyToClipboard,
  fetcher,
  formatDate,
  formatNumber,
  toggleBalance,
} from "../utils";
import { ReactComponent as SavingsIcon } from "../assets/svgs/savings.svg";
import { BS_API_GATEWAY_URL, TOAST_TIMEOUT_MS } from "../configs";
import { FaEllipsisVertical } from "react-icons/fa6";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const pagination: PaginatedRequest = { page: 0, size: 10 };

export const Savings = React.memo(function AccessSavings(): JSX.Element {
  const { user } = useAuth();
  const { appState } = useAppState();
  const [userTransactions, setUserTransactions] = useState<
    | {
        ledger?: PaginatedSolowalletTxsResponse;
        meta?: WalletMeta;
      }
    | undefined
  >();

  const toast = useToast();

  const {
    isOpen: showPersonalSavingsInfoModal,
    onOpen: onOpenPersonalSavingsInfoModal,
    onClose: onClosePersonalSavingsInfoModal,
  } = useDisclosure();
  const {
    isOpen: showPersonalSavingsContinueModal,
    onOpen: onOpenPersonalSavingsContinueModal,
    onClose: onClosePersonalSavingsContinueModal,
  } = useDisclosure();

  const [pendingTx, setPendingTx] = useState<SolowalletTx | undefined>();

  const fetchTransactions = async (page: number) => {
    if (!user) return;

    const data = await fetcher<UserTxsResponse, UserTxsRequest>(
      `${BS_API_GATEWAY_URL}/solowallet/transactions`,
      "POST",
      {
        userId: user.id,
        pagination: { page, size: pagination.size },
      },
    );

    setUserTransactions({ ...data });
  };

  useEffect(() => {
    if (!user) return;

    (async () => {
      try {
        const data = await fetcher<UserTxsResponse, UserTxsRequest>(
          `${BS_API_GATEWAY_URL}/solowallet/transactions`,
          "POST",
          {
            userId: user.id,
            pagination,
          },
        );

        setUserTransactions({ ...data });
      } catch (e) {
        console.log(e);
      }
    })();
  }, [user]);

  return (
    <>
      <Flex direction="column" flexGrow={1} basis="100%" gap="5" p="5" pb="20">
        <Flex
          p="5"
          gap="4"
          direction="row"
          flexWrap={{ base: "wrap", lg: "nowrap" }}
          align="center"
          justifyItems="center"
          justify="space-between"
        >
          <Flex
            direction="row"
            flexWrap={{ base: "wrap", lg: "nowrap" }}
            justify={{ base: "start", lg: "center" }}
            align={{ base: "start", lg: "center" }}
            gap={2}
          >
            <Heading size="md">PERSONAL SAVINGS</Heading>
            <Text>Save in bitcoin and grow your wealth</Text>
          </Flex>
          <Button
            variant="outline"
            colorScheme="green"
            height="35px"
            onClick={onOpenPersonalSavingsInfoModal}
          >
            about personal savings
            <Icon as={FaInfoCircle} ml="2" />
          </Button>
        </Flex>
        {appState.memberStatus.hasShares ? (
          <Flex direction="column" my="12" gap={10}>
            {user && (
              <BalanceCard
                user={user}
                walletMeta={userTransactions?.meta}
                setUserTransactions={(
                  ledger?: PaginatedSolowalletTxsResponse,
                  meta?: WalletMeta,
                ) => setUserTransactions({ ledger, meta })}
              />
            )}
            <Flex direction="column">
              <Text mb="4">TRANSACTION HISTORY</Text>
              <Flex direction="column" width={"100%"} gap="3">
                <Flex overflowX={"auto"}>
                  <Table variant={"striped"} minWidth={"650px"}>
                    <Thead>
                      <Tr>
                        <Th>Date</Th>
                        <Th>Amount (sats)</Th>
                        <Th>Amount (KES)</Th>
                        <Th>Type</Th>
                        <Th>Status</Th>
                        <Th>Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {userTransactions?.ledger?.transactions?.map((tx) => (
                        <Tr key={tx.id}>
                          <Td>{formatDate(tx.updatedAt || tx.createdAt)}</Td>
                          <Td>{formatNumber((tx.amountMsats || 0) / 1000)}</Td>
                          <Td>{tx.amountFiat}</Td>
                          <Td>{getTxTypeBadge(tx.type)}</Td>
                          <Td>{getTxStatusBadge(tx.status)}</Td>
                          <Td>
                            <Menu>
                              <MenuButton
                                as={IconButton}
                                icon={<FaEllipsisVertical />}
                                variant="outline"
                                aria-label="Options"
                              />
                              <MenuList>
                                <MenuItem
                                  onClick={() => {
                                    (async () => {
                                      try {
                                        await copyToClipboard(tx.id);
                                        toast({
                                          title: "Success",
                                          description: `Copied transaction ID to clipboard.`,
                                          status: "success",
                                          duration: TOAST_TIMEOUT_MS,
                                          isClosable: true,
                                        });
                                      } catch (e) {
                                        console.error(e);
                                      }
                                    })();
                                  }}
                                >
                                  Copy ID
                                </MenuItem>
                                {tx.status === TransactionStatus.PENDING &&
                                  tx.type === TransactionType.DEPOSIT && (
                                    <MenuItem
                                      onClick={() => {
                                        setPendingTx(tx);
                                        onOpenPersonalSavingsContinueModal();
                                      }}
                                    >
                                      Complete Transaction
                                    </MenuItem>
                                  )}
                              </MenuList>
                            </Menu>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Flex>
                <Flex justify="space-between" mt="4">
                  {userTransactions?.ledger &&
                    userTransactions.ledger.pages > 1 && (
                      <>
                        <Button
                          onClick={() => {
                            const cp = userTransactions?.ledger?.page || 0;
                            fetchTransactions(cp - 1);
                          }}
                          isDisabled={userTransactions.ledger?.page < 1}
                        >
                          Previous
                        </Button>
                        <Text>
                          {`page ${userTransactions.ledger.page + 1} of ${userTransactions.ledger.pages}`}
                        </Text>
                        <Button
                          onClick={() => {
                            const cp = userTransactions?.ledger?.page || 0;
                            fetchTransactions(cp + 1);
                          }}
                          isDisabled={
                            userTransactions.ledger.page + 1 ===
                            userTransactions.ledger.pages
                          }
                        >
                          Next
                        </Button>
                      </>
                    )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <FeatureTease
            img={SavingsIcon}
            title="Welcome to Bitsacco Personal Savings!"
          />
        )}
      </Flex>
      <PersonalSavingsInfoModal
        isOpen={showPersonalSavingsInfoModal}
        onClose={() => onClosePersonalSavingsInfoModal()}
      />
      {user && pendingTx && (
        <PersonalSavingsContinueModal
          pendingTx={pendingTx}
          txTarget={{
            target: {
              id: pendingTx.id,
              name: pendingTx.reference,
            },
            user,
          }}
          isOpen={showPersonalSavingsContinueModal}
          onClose={() => {
            onClosePersonalSavingsContinueModal();
            setPendingTx(undefined);
          }}
          setUserTransactions={(
            ledger?: PaginatedSolowalletTxsResponse,
            meta?: WalletMeta,
          ) => setUserTransactions({ ledger, meta })}
          pagination={pagination}
        />
      )}
    </>
  );
});

interface BalanceCardProps {
  user: User;
  walletMeta?: WalletMeta;
  setUserTransactions: (
    deposits?: PaginatedSolowalletTxsResponse,
    meta?: WalletMeta,
  ) => void;
}

const BalanceCard = React.memo(function BalanceCard({
  user,
  walletMeta,
  setUserTransactions,
}: BalanceCardProps): JSX.Element {
  const quote = useQuote();
  const { userSettings, updateUserSettings } = useAppState();

  const {
    isOpen: showDepositModal,
    onOpen: onOpenDepositModal,
    onClose: onCloseDepositModal,
  } = useDisclosure();

  const {
    isOpen: showWithdrawModal,
    onOpen: onOpenWithdrawModal,
    onClose: onCloseWithdrawModal,
  } = useDisclosure();

  const txTarget: TransactionTarget = {
    target: {
      id: "bps",
      name: "Bitsacco Personal Savings",
    },
    user,
  };

  const showBalanceInSats = useCallback(() => {
    const bal = formatNumber((walletMeta?.currentBalance || 0) / 1000);
    return (
      <Heading fontSize={{ base: "30px", md: "48px" }} fontWeight="bold">
        {userSettings.hideBalances ? (
          <span>{"\u2022".repeat(6)}</span>
        ) : (
          <>&#8383; {bal} SATS</>
        )}
      </Heading>
    );
  }, [walletMeta, userSettings]);

  const showBalanceInFiat = useCallback(() => {
    if (!quote) {
      return <></>;
    }

    const { amountFiat } = btcToFiat({
      amountMsats: walletMeta?.currentBalance || 0,
      fiatToBtcRate: Number(quote.rate),
    });
    return (
      <Text fontSize={{ base: "24px", lg: "30px" }} fontWeight="bold">
        {userSettings.hideBalances ? (
          <span>{"\u2022".repeat(4)}</span>
        ) : (
          <>&#61; {formatNumber(amountFiat)} KES</>
        )}
      </Text>
    );
  }, [walletMeta, quote, userSettings]);

  return (
    <Flex
      display={"flex"}
      alignItems={"center"}
      justifyItems={"center"}
      justifyContent={"center"}
      w={"100%"}
    >
      <Card w={{ base: "600px", xl: "800px" }} h={"350px"}>
        <CardBody
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyItems={"center"}
          justifyContent={"center"}
          gap={3}
        >
          <Flex direction="row" alignItems={"center"} gap="3">
            <Text>you have</Text>
            <Button
              background={"none"}
              _hover={{ background: "none" }}
              onClick={() => toggleBalance(userSettings, updateUserSettings)}
              p={0}
              minW={0}
              h={"auto"}
              ml={1}
            >
              {userSettings.hideBalances ? <ViewIcon /> : <ViewOffIcon />}
            </Button>
          </Flex>
          {showBalanceInSats()}
          {showBalanceInFiat()}
        </CardBody>
        <CardFooter
          display={"flex"}
          alignItems={"center"}
          justifyItems={"center"}
          justifyContent={"center"}
        >
          <ButtonGroup
            gap={"4"}
            spacing={{ base: "0", lg: "4" }}
            display={"flex"}
            flexDir={"row"}
            flexBasis={"100%"}
            flexWrap={{ base: "wrap", lg: "nowrap" }}
          >
            <Button
              variant="solid"
              colorScheme="green"
              onClick={onOpenDepositModal}
              height={"45px"}
              minW={"150px"}
              flexGrow={1}
            >
              Deposit Funds
            </Button>
            <Button
              variant="solid"
              colorScheme="teal"
              onClick={onOpenWithdrawModal}
              height={"45px"}
              minW={"150px"}
              flexGrow={1}
              disabled={true}
            >
              Withdraw Funds
            </Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
      <PersonalSavingsDepositModal
        txTarget={txTarget}
        setUserTransactions={setUserTransactions}
        onClose={onCloseDepositModal}
        isOpen={showDepositModal}
        pagination={pagination}
      />
      <PersonalSavingsWithdrawModal
        txTarget={txTarget}
        setUserTransactions={setUserTransactions}
        onClose={onCloseWithdrawModal}
        isOpen={showWithdrawModal}
        pagination={pagination}
      />
    </Flex>
  );
});
