import React, { useCallback } from "react";
import {
  DepositFundsRequest,
  WalletMeta,
  UserTxsResponse,
  UserTxsRequest,
  OnrampSwapSource,
  PaginatedSolowalletTxsResponse,
  SolowalletTx,
  TxPath,
  TransactionType,
  ContinueTxRequest,
  PaginatedRequest,
  FindTxRequest,
} from "@bitsacco/types";
import { BS_API_GATEWAY_URL } from "../../configs";
import { ActiveTx, TransactionTarget } from "../transactions";
import { StatusToTransactionState } from "../TransactionState";
import { TransactionModal } from "./TransactionModal";
import { fetcher } from "../../utils";

interface PersonalSavingsContinueModalProps {
  pendingTx: SolowalletTx;
  txTarget: TransactionTarget;
  setUserTransactions: (
    ledger?: PaginatedSolowalletTxsResponse,
    meta?: WalletMeta,
  ) => void;
  isOpen: boolean;
  onClose: () => void;
  pagination?: PaginatedRequest;
}

export const PersonalSavingsContinueModal = React.memo(
  function PersonalSavingsContinueModal({
    pendingTx,
    txTarget,
    setUserTransactions,
    isOpen,
    onClose,
    pagination,
  }: PersonalSavingsContinueModalProps): JSX.Element {
    const validate = ({
      txId,
      ledger,
    }: Partial<UserTxsResponse>): SolowalletTx => {
      if (!ledger?.transactions || ledger.transactions.length < 1) {
        throw new Error("Failed to create a deposit transaction");
      }

      const tx = ledger.transactions.find((tx) => tx.id === txId);

      if (!tx) {
        throw new Error("Failed to create a deposit transaction");
      }

      return tx;
    };

    const createTx = useCallback(
      (path: TxPath, amount: number) => {
        return (async (): Promise<ActiveTx> => {
          try {
            const onramp: OnrampSwapSource | undefined =
              path === TxPath.Mpesa
                ? {
                    currency: "KES" as unknown as any,
                    origin: {
                      phone: txTarget.user.phone?.replace("+", "") || "",
                    },
                  }
                : undefined;

            const res = await fetcher<UserTxsResponse, ContinueTxRequest>(
              `${BS_API_GATEWAY_URL}/solowallet/continue`,
              "POST",
              {
                userId: txTarget.user.id,
                txId: pendingTx.id,
                amountFiat: amount,
                onramp,
                pagination,
              },
            );

            const tx = validate(res);
            setUserTransactions(res.ledger, res.meta);

            return {
              path,
              id: tx.id,
              type: tx.type,
              lightning: tx.lightning,
              state: StatusToTransactionState(tx.status),
            };
          } catch (e) {
            throw e;
          }
        })();
      },
      [pendingTx, txTarget, setUserTransactions],
    );

    const findTx = async (activeTx: ActiveTx) => {
      try {
        const tx = await fetcher<SolowalletTx, FindTxRequest>(
          `${BS_API_GATEWAY_URL}/solowallet/find/id/${activeTx.id}`,
          "GET",
        );
        return {
          ...activeTx,
          state: StatusToTransactionState(tx.status),
        };
      } catch (e) {
        throw e;
      }
    };

    return (
      <TransactionModal
        isOpen={isOpen}
        onClose={onClose}
        createTx={createTx}
        findTx={findTx}
        txTarget={txTarget}
        txType={TransactionType.DEPOSIT}
        fixedAmount={pendingTx.amountFiat}
      />
    );
  },
);
