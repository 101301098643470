"use strict";
// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.7
//   protoc               v3.20.3
// source: shares.proto
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharesTxStatus = void 0;
var SharesTxStatus;
(function (SharesTxStatus) {
    SharesTxStatus[SharesTxStatus["PROPOSED"] = 0] = "PROPOSED";
    SharesTxStatus[SharesTxStatus["PROCESSING"] = 1] = "PROCESSING";
    SharesTxStatus[SharesTxStatus["APPROVED"] = 2] = "APPROVED";
    SharesTxStatus[SharesTxStatus["COMPLETE"] = 3] = "COMPLETE";
    SharesTxStatus[SharesTxStatus["FAILED"] = 4] = "FAILED";
    SharesTxStatus[SharesTxStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(SharesTxStatus || (exports.SharesTxStatus = SharesTxStatus = {}));
