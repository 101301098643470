import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Flex,
  Text,
  useToast,
  Progress,
  Icon,
  useDisclosure,
  Card,
  CardBody,
  Heading,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Badge,
} from "@chakra-ui/react";
import { FaInfoCircle } from "react-icons/fa";
import {
  PaginatedUserSharesTxsResponse,
  SharesOffer,
  SharesTx,
  SharesTxStatus,
  SharesTxUpdates,
  SubscribeSharesRequest,
  UpdateSharesRequest,
  UserSharesTxsRequest,
  UserShareTxsResponse,
} from "@bitsacco/types";
import {
  ComingSoon,
  MembershipInfoModal,
  ShareInputGroup,
  SharesTxModal,
  useAppState,
  useAuth,
} from "../components";
import { BS_API_GATEWAY_URL } from "../configs";
import { fetcher, formatDate, formatNumber, toggleBalance } from "../utils";
import { FaEllipsisVertical } from "react-icons/fa6";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const BITSACCO_SHARE_VALUE_KES = 1000;

export const SaccoMembership = () => {
  const { user } = useAuth();

  const [shareOrder, setShareOrder] = useState<number>(0);
  const [shareHoldings, setShareHoldings] = useState<number>(0);
  const [shareActivity, setShareActivity] = useState<
    PaginatedUserSharesTxsResponse | undefined
  >();
  const [activeSharesTx, setActiveSharesTx] = useState<SharesTx | undefined>(
    undefined,
  );
  const [activeOffer, setActiveOffer] = useState<SharesOffer | undefined>(
    undefined,
  );

  const { userSettings, updateUserSettings } = useAppState();

  const toast = useToast();
  const {
    isOpen: showMembershipInfoModal,
    onOpen: onOpenMembershipInfoModal,
    onClose: onCloseMembershipInfoModal,
  } = useDisclosure();
  const {
    isOpen: showSharesTxModal,
    onOpen: onOpenSharesTxModal,
    onClose: onCloseSharesTxModal,
  } = useDisclosure();

  const fetchSharesTx = async (page: number) => {
    if (!user) return;

    const response = await fetcher<UserShareTxsResponse, UserSharesTxsRequest>(
      `${BS_API_GATEWAY_URL}/shares/transactions/${user.id}/?page=${page}&size=10`,
      "GET",
    );

    setShareActivity(response.shares);
  };

  useEffect(() => {
    if (!user) return;

    (async () => {
      try {
        const response = await fetcher<UserShareTxsResponse, undefined>(
          `${BS_API_GATEWAY_URL}/shares/transactions/${user.id}/?page=0&size=10`,
          "GET",
        );

        updateState(response);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [user]);

  const updateState = useCallback(
    (response: UserShareTxsResponse) => {
      if (response.shareHoldings) {
        setShareHoldings(response.shareHoldings);
        setShareActivity(response.shares);
      }

      if (response.offers) {
        setActiveOffer(
          response.offers.offers?.find((offer) => {
            return offer.subscribedQuantity < offer.quantity;
          }),
        );
      }
    },
    [setShareHoldings, setShareActivity, setActiveOffer],
  );

  const subscribeShares = useCallback(() => {
    (async () => {
      if (shareOrder <= 0 || !activeOffer || !user) return;

      try {
        const response = await fetcher<
          UserShareTxsResponse,
          SubscribeSharesRequest
        >(`${BS_API_GATEWAY_URL}/shares/subscribe`, "POST", {
          userId: user.id,
          offerId: activeOffer.id,
          quantity: shareOrder,
        });
        setShareOrder(0);
        updateState(response);

        setActiveSharesTx(response.shares?.transactions[0]);
        onOpenSharesTxModal();
      } catch (error) {
        console.error(error);
        toast({
          title: "Error",
          description:
            "Subscribe shares falied. Please try again later or contact us",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    })();
  }, [
    shareOrder,
    activeOffer,
    user,
    updateState,
    toast,
    setShareOrder,
    onOpenSharesTxModal,
    toast,
  ]);

  const updateSharesTx = (updates: SharesTxUpdates) => {
    if (!activeSharesTx) return;

    (async () => {
      try {
        let response = await fetcher<UserShareTxsResponse, UpdateSharesRequest>(
          `${BS_API_GATEWAY_URL}/shares/update`,
          "POST",
          {
            sharesId: activeSharesTx.id,
            updates,
          },
        );

        if (response.shares) {
          setShareHoldings(response.shareHoldings);
          setShareActivity(response.shares);
        }

        if (response.offers) {
          setActiveOffer(
            response.offers.offers.find((offer) => {
              return offer.subscribedQuantity < offer.quantity;
            }),
          );
        }
      } catch (error) {
        console.error(error);
      }
    })();
  };

  const renderShareStatus = (status: SharesTxStatus) => {
    switch (status) {
      case SharesTxStatus.APPROVED:
        return <Badge colorScheme="green">approved</Badge>;
      case SharesTxStatus.COMPLETE:
        return <Badge colorScheme="green">complete</Badge>;

      case SharesTxStatus.PROPOSED:
        return <Badge colorScheme="gray">proposed</Badge>;
      case SharesTxStatus.PROCESSING:
        return <Badge colorScheme="teal">processing</Badge>;

      case SharesTxStatus.FAILED:
        return <Badge colorScheme="red">failed</Badge>;
      case SharesTxStatus.UNRECOGNIZED:
        return <Badge colorScheme="red">unrecognized</Badge>;
    }
  };

  return (
    <>
      <Flex direction="column" flexGrow={1} basis="100%" gap="5" p="5" pb="20">
        <Flex
          p="5"
          gap="4"
          direction="row"
          flexWrap={{ base: "wrap", lg: "nowrap" }}
          align="center"
          justifyItems="center"
          justify="space-between"
        >
          <Flex
            direction="row"
            flexWrap={{ base: "wrap", lg: "nowrap" }}
            justify={{ base: "start", lg: "center" }}
            align={{ base: "start", lg: "center" }}
            gap={2}
          >
            <Heading size="md">MEMBERSHIP</Heading>
            <Text>Subscribe, track and manage your Bitsacco member shares</Text>
          </Flex>
          <Button
            variant="outline"
            colorScheme="green"
            height="35px"
            onClick={onOpenMembershipInfoModal}
          >
            about membership
            <Icon as={FaInfoCircle} ml="2" />
          </Button>
        </Flex>

        <Flex
          display={"flex"}
          alignItems={"center"}
          justifyItems={"center"}
          justifyContent={"center"}
          w={"100%"}
        >
          <Card w={{ base: "600px", xl: "800px" }} h={"350px"}>
            <CardBody
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyItems={"center"}
              justifyContent={"center"}
              gap={3}
            >
              <Flex direction={"row"} alignItems={"center"} gap="3">
                <Text>you own</Text>
                <Button
                  background={"none"}
                  _hover={{ background: "none" }}
                  onClick={() =>
                    toggleBalance(userSettings, updateUserSettings)
                  }
                  p={0}
                  minW={0}
                  ml={1}
                >
                  {userSettings.hideBalances ? <ViewIcon /> : <ViewOffIcon />}
                </Button>
              </Flex>
              <Heading
                fontSize={{ base: "30px", md: "48px" }}
                fontWeight="bold"
              >
                {userSettings.hideBalances ? (
                  <span>{"\u2022".repeat(6)}</span>
                ) : (
                  <>&#61; {shareHoldings} SHARES</>
                )}
              </Heading>

              <Text fontSize={{ base: "24px", lg: "30px" }} fontWeight="bold">
                {userSettings.hideBalances ? (
                  <span>{"\u2022".repeat(4)}</span>
                ) : (
                  <>
                    &#61;{" "}
                    {formatNumber(shareHoldings * BITSACCO_SHARE_VALUE_KES)} KES
                  </>
                )}
              </Text>
              <Flex
                direction="row"
                flexWrap={{ base: "wrap", lg: "nowrap" }}
                alignItems={"center"}
                justifyItems={"center"}
                justifyContent={"center"}
                gap={5}
                pt={4}
              >
                <ShareInputGroup
                  shares={shareOrder}
                  setShare={setShareOrder}
                  getFormHelperText={() => <></>}
                />
                <Button
                  colorScheme="teal"
                  isDisabled={shareOrder <= 0}
                  onClick={subscribeShares}
                  height={"45px"}
                  minW={"150px"}
                  flexGrow={1}
                >
                  Subscribe Shares
                </Button>
              </Flex>
            </CardBody>
          </Card>
        </Flex>

        {activeOffer && (
          <Flex direction="column" my="12" gap={2}>
            <Text mb="2">
              <strong>BITSACCO BETA</strong> SHARE SUBSCRIPTIONS
            </Text>
            <Progress
              value={
                (activeOffer.subscribedQuantity / activeOffer.quantity) * 100
              }
              size="md"
              colorScheme="green"
              borderRadius="sm"
            />
            <Flex justify="space-between" pt={2}>
              <Text mb="2">
                {activeOffer.subscribedQuantity} shares subscribed
              </Text>
              <Text mb="2">{activeOffer.quantity} shares available</Text>
            </Flex>
          </Flex>
        )}

        <Flex direction="column">
          <Text mb="4">SHARE HISTORY</Text>
          <Flex direction={"column"} gap="3" width={"100%"}>
            <Flex overflowX={"auto"}>
              <Table variant={"striped"} minWidth={"650px"}>
                <Thead>
                  <Tr>
                    <Th>Date</Th>
                    <Th>Shares</Th>
                    <Th>Status</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {shareActivity?.transactions &&
                    shareActivity?.transactions.map((shares, index) => (
                      <Tr key={index}>
                        <Td>
                          {formatDate(shares.updatedAt || shares.createdAt)}
                        </Td>
                        <Td>{shares.quantity}</Td>
                        <Td>{renderShareStatus(shares.status)}</Td>
                        <Td>
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              icon={<FaEllipsisVertical />}
                              variant="outline"
                              aria-label="Options"
                            />
                            <MenuList>
                              {shares.status === SharesTxStatus.COMPLETE ? (
                                <MenuItem
                                  onClick={() => {
                                    setActiveSharesTx(shares);
                                    // onOpenDeleteShareTxModal();
                                  }}
                                >
                                  Transfer Shares
                                  <ComingSoon />
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  onClick={() => {
                                    setActiveSharesTx(shares);
                                    onOpenSharesTxModal();
                                  }}
                                >
                                  Complete Subscription
                                </MenuItem>
                              )}
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Flex>
            <Flex justify="space-between" mt="4">
              {shareActivity && shareActivity.pages > 1 && (
                <>
                  <Button
                    onClick={() => {
                      const currentPage = shareActivity.page || 0;
                      fetchSharesTx(currentPage - 1);
                    }}
                    isDisabled={shareActivity.page < 1}
                  >
                    previous
                  </Button>
                  <Text>
                    {`page ${shareActivity.page + 1} of ${shareActivity.pages}`}
                  </Text>
                  <Button
                    onClick={() => {
                      const currentPage = shareActivity.page || 0;
                      console.log(currentPage);

                      fetchSharesTx(currentPage + 1);
                    }}
                    isDisabled={shareActivity.page + 1 === shareActivity.pages}
                  >
                    next
                  </Button>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <MembershipInfoModal
        isOpen={showMembershipInfoModal}
        onClose={onCloseMembershipInfoModal}
      />
      {activeSharesTx && user && (
        <SharesTxModal
          sharesTxValue={activeSharesTx.quantity * BITSACCO_SHARE_VALUE_KES}
          txTarget={{
            target: {
              id: activeSharesTx.id,
              name: `pay for ${activeSharesTx.quantity} bitsacco shares`,
            },
            user,
          }}
          isOpen={showSharesTxModal}
          onClose={() => {
            onCloseSharesTxModal();
            setActiveSharesTx(undefined);
          }}
          updateSharesTx={updateSharesTx}
        />
      )}
    </>
  );
};
