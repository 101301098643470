"use strict";
// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.7
//   protoc               v3.20.3
// source: solowallet.proto
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionType = void 0;
var TransactionType;
(function (TransactionType) {
    TransactionType[TransactionType["DEPOSIT"] = 0] = "DEPOSIT";
    TransactionType[TransactionType["WITHDRAW"] = 1] = "WITHDRAW";
    TransactionType[TransactionType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(TransactionType || (exports.TransactionType = TransactionType = {}));
