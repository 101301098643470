import React from "react";
import {
  Flex,
  Text,
  IconButton,
  useTheme,
  useColorMode,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import { SunIcon, MoonIcon } from "@chakra-ui/icons";
import {
  FaTwitter,
  FaLinkedin,
  FaSquareWhatsapp,
  FaDiscord,
  FaPaperPlane,
} from "react-icons/fa6";

export const Footer = React.memo(function Footer() {
  const { colorMode, toggleColorMode } = useColorMode();
  const theme = useTheme();

  const bg = useColorModeValue(theme.colors.gray[50], theme.colors.gray[700]);

  return (
    <>
      <Flex
        direction={{ base: "column", sm: "row" }}
        align="center"
        justify="space-between"
        w="100vw"
        maxW="1440px"
        minH="100px"
        py="3"
        px={{ base: "12px", md: "20px", lg: "40px", xl: "100px" }}
        bg={bg}
        gap={{ base: 4, md: 0 }}
      >
        <Flex flexDirection="column" justifyContent="center" flexGrow={1}>
          <Text fontSize="xl" fontWeight="semibold">
            © BITSACCO
          </Text>
        </Flex>
        <Flex
          gap={2}
          direction="row"
          alignItems={{ base: "center", md: "flex-end" }}
          justifyContent={{ base: "center", md: "flex-end" }}
          flexWrap={{ base: "wrap", lg: "nowrap" }}
        >
          <IconButton
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            href="https://x.com/bitsacco"
            variant="outline"
            aria-label="View Bitsacco on X (formerly, Twitter)"
            icon={<FaTwitter />}
          />
          <IconButton
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            href="https://linkedin.com/company/bitsacco/"
            variant="outline"
            aria-label="Visit Bitsacco LinkedIn"
            icon={<FaLinkedin />}
          />
          <IconButton
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            href="https://discord.gg/gqM3m47yhn"
            variant="outline"
            aria-label="Join Bitsacco Discord"
            icon={<FaDiscord />}
          />
          <IconButton
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:contact@bitsacco.com"
            variant="outline"
            aria-label="Send Email to contact@bitsacco.com"
            icon={<FaPaperPlane />}
          />
          <IconButton
            variant="outline"
            aria-label="Toggle theme"
            icon={colorMode === "dark" ? <SunIcon /> : <MoonIcon />}
            onClick={toggleColorMode}
          />
        </Flex>
        <Flex
          flexGrow={1}
          gap={2}
          direction="row"
          alignItems={{ base: "center", md: "flex-end" }}
          justifyContent={{ base: "center", md: "flex-end" }}
        >
          <Link
            href="https://mempool.space/block/0000000000000000000320283a032748cef8227873ff4872689bf23f1cda83a5"
            isExternal
            fontSize="xl"
            display={"flex"}
            alignItems={{ base: "center", md: "flex-start" }}
            justifyContent={{ base: "center", md: "flex-start" }}
            color="teal"
          >
            EST. 840,000
          </Link>
        </Flex>
        <Link
          gap={1}
          position="fixed"
          bottom={{ base: "1rem", md: "1.5rem" }}
          right={{ base: "1rem", md: "2rem" }}
          borderRadius="full"
          zIndex={999}
          href="https://wa.me/+254708420214"
          isExternal
          _hover={{
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          <FaSquareWhatsapp size={50} />
        </Link>
      </Flex>
    </>
  );
});
