import React, { useCallback, useEffect, useState } from "react";
import {
  InputGroup,
  Input,
  FormHelperText,
  InputLeftAddon,
  InputRightAddon,
  Select,
  PinInputField,
  PinInput,
  FormControl,
  Flex,
  Tag,
  TagCloseButton,
} from "@chakra-ui/react";
import {
  getAsYouType,
  ParsedPhoneNumber,
  AsYouType,
  parsePhoneNumber,
  getSupportedRegionCodes,
} from "awesome-phonenumber";
import { BS_DOMAIN, PhoneRegionCode } from "../configs";
import { isValidAddress, isValidNpub } from "../utils";

// type CountryCode = ReturnType<typeof getCountries>[number];

export interface PhoneInputGroupProps {
  phone: string;
  setPhone: (phone: string) => void;
  placeholder?: string;
  showCountryCode?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const PhoneInputGroup = React.memo(function PhoneInputGroup({
  phone,
  setPhone,
  placeholder,
  showCountryCode = true,
  onKeyDown,
}: PhoneInputGroupProps): JSX.Element {
  const [number, setNumber] = useState<ParsedPhoneNumber | undefined>(
    parsePhoneNumber(phone),
  );
  const defaultRegionCode = "KE";
  const [ayt, setAyt] = useState<AsYouType>(
    getAsYouType(number?.regionCode || defaultRegionCode),
  );
  const [selectedCountry, setSelectedCountry] = useState<string>(
    number?.regionCode || defaultRegionCode,
  );
  const [phoneError, setPhoneError] = useState<string>();

  const handleCountryChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value;
      setSelectedCountry(value);

      const ayt = getAsYouType(value);
      setAyt(ayt);

      setPhone("");
      setNumber(undefined);
    },
    [setPhone, setNumber],
  );

  const handlePhoneChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      ayt.reset(value);
      const num = ayt.getPhoneNumber();

      if (num.possibility === "too-long") {
        setPhoneError("Phone number is too long");
        return;
      }

      if (!num.valid && num.possibility === "invalid") {
        setPhoneError("Invalid phone number");
      } else {
        setPhoneError("");
      }

      setPhone(num.number?.international || value);
      setNumber(num);
    },
    [ayt, setPhone, setNumber],
  );

  return (
    <>
      <InputGroup size="lg">
        {showCountryCode && (
          <InputLeftAddon m="0" p="0">
            <Select
              m="0"
              width="auto"
              value={selectedCountry}
              onChange={handleCountryChange}
            >
              {getSupportedRegionCodes().map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </Select>
          </InputLeftAddon>
        )}
        <Input
          type="tel"
          placeholder={placeholder || "Enter phone number"}
          focusBorderColor="teal.300"
          value={number?.number?.national || phone}
          onChange={handlePhoneChange}
          onKeyDown={(e) => {
            if (onKeyDown && !phoneError) {
              onKeyDown(e);
            }
          }}
          errorBorderColor="red.300"
          isInvalid={!!phoneError}
        />
      </InputGroup>
      {phoneError && (
        <FormHelperText color="red.300">{phoneError}</FormHelperText>
      )}
    </>
  );
});
export interface AmountInputGroupProps {
  amount: number;
  setAmount: (amount: number) => void;
  getFormHelperText: (amountError?: string) => JSX.Element;
  isDisabled?: boolean;
}

export const AmountInputGroup = React.memo(function AmountInputGroup({
  amount,
  setAmount,
  getFormHelperText,
  isDisabled = false,
}: AmountInputGroupProps): JSX.Element {
  const [amountError, setAmountError] = useState<string>();

  return (
    <>
      <InputGroup size="lg">
        <InputLeftAddon pointerEvents="none">KES</InputLeftAddon>
        <Input
          type="number"
          placeholder="enter amount"
          focusBorderColor="teal.300"
          value={(amount && amount.toString()) || ""}
          onChange={(e) => {
            const value = e.target.value;
            const amt = Number(value);
            if (isNaN(amt) || amt < 0) {
              setAmountError("Invalid deposit amount");
            } else {
              setAmount(amt);
              setAmountError("");
            }
          }}
          errorBorderColor="red.300"
          isInvalid={!!amountError}
          isDisabled={isDisabled}
        />
      </InputGroup>
      {getFormHelperText(amountError)}
    </>
  );
});

export interface ShareInputGroupProps {
  shares: number;
  setShare: (shares: number) => void;
  getFormHelperText: (sharesError?: string) => JSX.Element;
}

export const ShareInputGroup = React.memo(function ShareInputGroup({
  shares,
  setShare,
  getFormHelperText,
}: ShareInputGroupProps): JSX.Element {
  const [sharesError, setShareError] = useState<string>();

  return (
    <>
      <InputGroup size="lg">
        <Input
          type="number"
          textAlign="center"
          placeholder="enter number of shares"
          focusBorderColor="teal.300"
          value={(shares && shares.toString()) || ""}
          onChange={(e) => {
            const value = e.target.value;
            const amt = Number(value);
            if (isNaN(amt) || amt < 0) {
              setShareError("Invalid shares count");
            } else {
              setShare(amt);
              setShareError("");
            }
          }}
          errorBorderColor="red.300"
          isInvalid={!!sharesError}
        />
      </InputGroup>
      {getFormHelperText(sharesError)}
    </>
  );
});

export interface InvoiceInputGroupProps {
  invoice?: string;
  invoicePrefix: string;
  setInvoice: (invoice: string) => void;
  getFormHelperText: (invoiceError?: string) => JSX.Element;
}

export const InvoiceInputGroup = React.memo(function InvoiceInputGroup({
  invoice,
  invoicePrefix,
  setInvoice,
  getFormHelperText,
}: InvoiceInputGroupProps): JSX.Element {
  const [invoiceError, setInvoiceError] = useState<string>();

  return (
    <>
      <InputGroup size="lg">
        <InputLeftAddon pointerEvents="none">LN</InputLeftAddon>
        <Input
          placeholder="paste an invoice"
          focusBorderColor="teal.300"
          errorBorderColor="red.300"
          value={invoice}
          onChange={(e) => {
            const value = e.target.value;
            if (value.startsWith(invoicePrefix)) {
              setInvoice(value);
              setInvoiceError("");
            } else {
              setInvoiceError(
                "Please make sure you enter a valid Bitcoin lightning invoice.",
              );
            }
          }}
        />
      </InputGroup>
      {getFormHelperText(invoiceError)}
    </>
  );
});

export interface OptionInputGroupProps {
  placeholder?: string;
  left?: JSX.Element;
  options: string[];
  selectedOption?: string;
  setSelectedOption: (option: string) => void;
  getFormHelperText: (optionError?: string) => JSX.Element;
}

export const OptionInputGroup = React.memo(function OptionInputGroup({
  placeholder,
  left,
  options,
  selectedOption,
  setSelectedOption,
  getFormHelperText,
}: OptionInputGroupProps): JSX.Element {
  const [optionError, setOptionError] = useState<string>();

  return (
    <>
      <InputGroup size="lg">
        {left && <InputLeftAddon pointerEvents="none">{left}</InputLeftAddon>}
        <Select
          placeholder={placeholder}
          focusBorderColor="teal.300"
          value={selectedOption}
          onChange={(e) => {
            const value = e.target.value;
            if (!options.includes(value)) {
              setOptionError("Invalid option selected");
            } else {
              setSelectedOption(value);
              setOptionError("");
            }
          }}
          errorBorderColor="red.300"
          isInvalid={!!optionError}
        >
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </Select>
      </InputGroup>
      {getFormHelperText(optionError)}
    </>
  );
});

export interface PinInputGroupProps {
  pin?: string;
  setPin: (pin: string) => void;
  getFormHelperText?: (pinError?: string) => JSX.Element;
}

export const PinInputGroup = React.memo(function PinInputGroup({
  pin,
  setPin,
  getFormHelperText,
}: PinInputGroupProps): JSX.Element {
  const [pinError, setPinError] = useState<string>("");
  const [maskedPin, setMaskedPin] = useState<string>(
    pin?.replace(/./g, "•") || "",
  );

  const buildPin = (currentPin: string, value: string): string => {
    let newPin = "";

    for (let i = 0; i < value.length; i++) {
      newPin += value[i] === "•" ? currentPin[i] : value[i];
    }

    return newPin;
  };

  const handleChange = useCallback(
    (value: string) => {
      const proposedPin = buildPin(pin || "", value);

      if (!/^\d{0,6}$/.test(proposedPin)) {
        setPinError("PIN must be a 6-digit number");
      } else {
        setPin(proposedPin);
        setMaskedPin(proposedPin.replace(/./g, "•"));
        setPinError("");
      }
    },
    [setPin, pin, setPinError],
  );

  const getHelperText = useCallback(() => {
    if (pinError) {
      return getFormHelperText ? (
        getFormHelperText(pinError)
      ) : (
        <FormHelperText color="red.300">{pinError}</FormHelperText>
      );
    }

    return <></>;
  }, [pinError, getFormHelperText]);

  return (
    <>
      <InputGroup size="lg">
        <PinInput
          value={maskedPin}
          type="number"
          onChange={handleChange}
          focusBorderColor="teal.300"
          errorBorderColor="red.300"
          mask
        >
          {[...Array(6)].map((_, index) => (
            <PinInputField key={index} mx={"1"} inputMode="numeric" />
          ))}
        </PinInput>
      </InputGroup>
      {getHelperText()}
    </>
  );
});

export interface NameInputGroupProps {
  name: string;
  setName: (name: string) => void;
}

export const NameInputGroup = React.memo(function NameInputGroup({
  name,
  setName,
}: NameInputGroupProps): JSX.Element {
  const [nameError, setNameError] = useState<string>();
  return (
    <FormControl>
      <InputGroup size="lg">
        <Input
          type="text"
          placeholder="enter name"
          focusBorderColor="teal.300"
          value={name}
          onChange={(e) => {
            const value = e.target.value;
            setName(value);
            if (!value) {
              setNameError("Name cannot be empty");
            } else if (/[^a-z A-Z]/.test(value)) {
              setNameError("Name must only contain letters");
            } else {
              setNameError("");
            }
          }}
          errorBorderColor="red.300"
          isInvalid={!!nameError}
        />
      </InputGroup>
      {nameError && (
        <FormHelperText color="red.300">{nameError}</FormHelperText>
      )}
    </FormControl>
  );
});

export interface AddressInputGroupProps {
  address: string;
  setAddress: (name: string) => void;
}

export const AddressInputGroup = React.memo(function AddressInputGroup({
  address,
  setAddress,
}: AddressInputGroupProps): JSX.Element {
  const [name, setName] = useState<string>(address.split("@")[0]!);
  const [nameError, setNameError] = useState<string>();

  useEffect(() => {
    const paddress = `${name}@${BS_DOMAIN}`;

    if (isValidAddress(paddress)) {
      setAddress(paddress);
    }
  }, [name, setAddress]);

  return (
    <FormControl>
      <InputGroup size="lg" display="flex">
        <Input
          type="text"
          placeholder="enter username"
          focusBorderColor="teal.300"
          value={name}
          onChange={(e) => {
            const value = e.target.value;
            setName(value);

            if (!value) {
              setNameError("Address cannot be empty");
            } else {
              setNameError("");
            }
          }}
          errorBorderColor="red.300"
          isInvalid={!!nameError}
          flexGrow={1}
          flexBasis={1}
        />
        <InputRightAddon
          fontWeight="600"
          bgColor="transparent"
          borderLeft="none"
          fontSize="15px"
          flexGrow={1}
          flexBasis={1}
        >
          @{BS_DOMAIN}
        </InputRightAddon>
      </InputGroup>
      {nameError && (
        <FormHelperText color="red.300">{nameError}</FormHelperText>
      )}
    </FormControl>
  );
});

interface TagPhoneInputGroupProps {
  phoneTags: string[];
  setPhoneTags: (phoneTags: string[]) => void;
}

export const TagPhoneInputGroup = React.memo(function TagPhoneInputGroup({
  phoneTags,
  setPhoneTags,
}: TagPhoneInputGroupProps) {
  const [phone, setPhone] = useState<string>("");

  useEffect(() => {
    if (phone.trim() !== "") {
      const ayt = getAsYouType(PhoneRegionCode.Kenya);
      ayt.reset(phone);

      if (ayt.getPhoneNumber().valid) {
        setPhoneTags([...phoneTags, phone.trim()]);
        setPhone("");
      }
    }
  }, [phone, phoneTags, setPhone, setPhoneTags]);

  const handleRemoveTag = (index: number) => {
    const newTags = [...phoneTags];
    newTags.splice(index, 1);
    setPhoneTags(newTags);
  };

  return (
    <Flex flexWrap="wrap" gap={2}>
      {phoneTags.map((phone, index) => (
        <Tag key={index} size="md" variant="solid" colorScheme="blue">
          {phone}
          <TagCloseButton onClick={() => handleRemoveTag(index)} />
        </Tag>
      ))}
      <PhoneInputGroup
        phone={phone}
        setPhone={setPhone}
        placeholder="type phone number to invite member"
        showCountryCode={false}
      />
    </Flex>
  );
});

export interface NpubInputGroupProps {
  npub: string;
  setNpub: (npub: string) => void;
}

export const NpubInputGroup = React.memo(function NpubInputGroup({
  npub,
  setNpub,
}: NpubInputGroupProps): JSX.Element {
  const [npubError, setNpubError] = useState<string>();
  return (
    <FormControl>
      <InputGroup size="lg">
        <Input
          type="text"
          placeholder="enter nostr public id"
          focusBorderColor="teal.300"
          value={npub}
          onChange={(e) => {
            const value = e.target.value;
            setNpub(value);
            if (!isValidNpub(value)) {
              setNpubError("Invalid Nostr ID");
            } else {
              setNpubError("");
            }
          }}
          errorBorderColor="red.300"
          isInvalid={!!npubError}
        />
      </InputGroup>
      {npubError && (
        <FormHelperText color="red.300">{npubError}</FormHelperText>
      )}
    </FormControl>
  );
});
