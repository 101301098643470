import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Alert,
  AlertIcon,
  Center,
  Spinner,
  useToast,
  useDisclosure,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Badge,
  Flex,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { FaEllipsisVertical } from "react-icons/fa6";
import {
  AllSharesTxsResponse,
  SharesOffer,
  SharesTx,
  SharesTxStatus,
  SharesTxUpdates,
  UpdateSharesRequest,
  User,
  UserShareTxsResponse,
} from "@bitsacco/types";
import { BS_API_GATEWAY_URL, BS_API_URL } from "../configs";
import { QueryKeys } from "../enums/QueryKeys";
import { DeleteShareTxModal, UpdateShareTxModal, useAuth } from "../components";
import { fetcher, formatDate, getProfileLabel } from "../utils";
import { FaSearch } from "react-icons/fa";

const ADMINS = [
  "dd3a83c4-9ff2-4129-98eb-44ad6612790c",
  "7b158dfd-cb98-40b1-9ed2-a13006a9f670",
  "fa94df81-de2a-41ea-bb72-2c193c68fccf",
];

export const ShareAdmin = () => {
  const toast = useToast();
  const { user } = useAuth();

  const [shareActivity, setShareActivity] = useState<SharesTx[]>([]);
  const [offers, setOffers] = useState<SharesOffer[]>([]);
  const [modShares, setModShares] = useState<SharesTx | undefined>(undefined);
  const [delShares, setDelShares] = useState<SharesTx | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (!user) return;

    (async () => {
      try {
        const { shares, offers } = await fetcher<
          AllSharesTxsResponse,
          undefined
        >(`${BS_API_GATEWAY_URL}/shares/transactions`, "GET");

        setShareActivity(shares?.transactions || []);
        setOffers(offers?.offers || []);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [user]);

  if (!user?.id) {
    return (
      <Center>
        <Text>You must be logged in to access this page.</Text>
      </Center>
    );
  }

  if (!ADMINS.includes(user.id)) {
    return (
      <Center>
        <Text>You are not authorized to access this page.</Text>
      </Center>
    );
  }

  const {
    isOpen: showUpdateShareTxModal,
    onOpen: onOpenUpdateShareTxModal,
    onClose: onCloseUpdateShareTxModal,
  } = useDisclosure();
  const {
    isOpen: showDeleteShareTxModal,
    onOpen: onOpenDeleteShareTxModal,
    onClose: onCloseDeleteShareTxModal,
  } = useDisclosure();

  let {
    data: users = [],
    isLoading: fetchLoading,
    error: fetchError,
  } = useQuery<User[], Error>({
    queryKey: [QueryKeys.CHAMAS],
    queryFn: async () => {
      return await fetcher<User[], Error>(`${BS_API_URL}/user/all`);
    },
    enabled: !!user?.id,
  });

  const filteredUsers = users.filter(
    (u) =>
      getProfileLabel(u).toLowerCase().includes(searchTerm.toLowerCase()) ||
      u.phone?.includes(searchTerm),
  );

  const filteredShares = shareActivity.filter(
    (s) => filteredUsers.find((u) => u.id === s.userId) !== undefined,
  );

  console.log(filteredShares);

  if (fetchLoading) {
    return (
      <Center w="100%" pt="5rem">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    );
  }

  if (fetchError) {
    return (
      <Alert status="error" mt="5rem">
        <AlertIcon />
        There was an error processing your request
      </Alert>
    );
  }

  const updateShareTx = (req: UpdateSharesRequest) => {
    (async () => {
      try {
        await fetcher<UserShareTxsResponse, UpdateSharesRequest>(
          `${BS_API_GATEWAY_URL}/shares/update`,
          "POST",
          req,
        );

        const { shares, offers } = await fetcher<
          AllSharesTxsResponse,
          undefined
        >(`${BS_API_GATEWAY_URL}/shares/transactions`, "GET");

        setShareActivity(shares?.transactions || []);
        setOffers(offers?.offers || []);

        toast({
          title: "Success",
          description: "Shares updated successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error(error);
        toast({
          title: "Error",
          description: "Failed to update shares",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    })();
  };

  const renderShareStatus = (status: SharesTxStatus) => {
    switch (status) {
      case SharesTxStatus.APPROVED:
        return <Badge colorScheme="green">approved</Badge>;
      case SharesTxStatus.COMPLETE:
        return <Badge colorScheme="green">complete</Badge>;

      case SharesTxStatus.PROPOSED:
        return <Badge colorScheme="gray">proposed</Badge>;
      case SharesTxStatus.PROCESSING:
        return <Badge colorScheme="teal">processing</Badge>;

      case SharesTxStatus.FAILED:
        return <Badge colorScheme="red">failed</Badge>;
      case SharesTxStatus.UNRECOGNIZED:
        return <Badge colorScheme="red">unrecognized</Badge>;
    }
  };

  return (
    <Box>
      <Heading as="h1" mb={4}>
        Shares Admin
      </Heading>
      <InputGroup mb={4}>
        <InputLeftElement pointerEvents="none">
          <FaSearch color="gray.300" />
        </InputLeftElement>
        <Input
          placeholder="Search by user name or phone number"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>
      <Flex overflowX={"auto"}>
        <Table>
          <Thead>
            <Tr>
              <Th>Created</Th>
              <Th>Updated</Th>
              <Th>Owner</Th>
              <Th>Quantity</Th>
              <Th>Offer</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredShares.map((s) => (
              <Tr key={s.id}>
                <Td>{formatDate(s.createdAt)}</Td>
                <Td>{formatDate(s.updatedAt || "")}</Td>
                <Td>{s.userId}</Td>
                <Td>{s.quantity}</Td>
                <Td>{s.offerId}</Td>
                <Td>{renderShareStatus(s.status)}</Td>
                <Td>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      icon={<FaEllipsisVertical />}
                      variant="outline"
                      aria-label="Options"
                    />
                    <MenuList>
                      <MenuItem
                        onClick={() => {
                          setModShares(s);
                          onOpenUpdateShareTxModal();
                        }}
                      >
                        Update Shares
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setDelShares(s);
                          onOpenDeleteShareTxModal();
                        }}
                      >
                        Soft Delete Shares
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          updateShareTx({
                            sharesId: s.id,
                            updates: {
                              status: SharesTxStatus.APPROVED,
                            },
                          });
                        }}
                      >
                        Approve
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Flex>
      {modShares && (
        <UpdateShareTxModal
          tx={modShares}
          isOpen={showUpdateShareTxModal}
          onClose={() => {
            onCloseUpdateShareTxModal();
            setModShares(undefined);
          }}
          updateShareTx={(updates: SharesTxUpdates) => {
            modShares && updateShareTx({ sharesId: modShares.id, updates });
            setModShares(undefined);
            onCloseUpdateShareTxModal();
          }}
        />
      )}
      {delShares && (
        <DeleteShareTxModal
          tx={delShares}
          isOpen={showDeleteShareTxModal}
          onClose={() => {
            onCloseDeleteShareTxModal();
            setDelShares(undefined);
          }}
          deleteShareTx={() => {
            delShares &&
              updateShareTx({
                sharesId: delShares.id,
                updates: {
                  status: SharesTxStatus.UNRECOGNIZED,
                },
              });
            setDelShares(undefined);
            onCloseDeleteShareTxModal();
          }}
        />
      )}
    </Box>
  );
};
