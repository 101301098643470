import React from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useDisclosure,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import {
  FaUserLock,
  FaLock,
  FaCircleNotch,
  FaArrowRight,
  FaBlog,
} from "react-icons/fa6";
import { useLocation } from "react-router-dom";

import { ReactComponent as Logo } from "../assets/svgs/logo.svg";
import { ReactComponent as LogoFull } from "../assets/svgs/logo_full.svg";
import { RouterLink } from "./RouterLink";
import { useAuth } from "./Providers";
import { Headshot } from "./Headshot";
import { BS_BLOG_URL } from "../configs";
import { Routes } from "../routes";

export const Header = React.memo(function Header(): JSX.Element {
  const { user, logout } = useAuth();
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const [isSmallScreen] = useMediaQuery("(max-width: 767px)");

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      w={"100%"}
      maxW="1440px"
      p={4}
    >
      <RouterLink to="/">
        <Icon
          as={isSmallScreen ? Logo : LogoFull}
          fill={theme.colors.teal[500]}
          height={{ base: "65px", md: "160px" }}
          width={"100%"}
        />
      </RouterLink>
      {isSmallScreen ? (
        <>
          <IconButton
            aria-label="Open menu"
            icon={<HamburgerIcon />}
            onClick={onOpen}
            variant="ghost"
          />
          <Drawer
            isOpen={isOpen}
            onClose={onClose}
            placement="right"
            size="full"
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader id="drawer-header">
                <Flex justify="space-between" align="center" width="100%">
                  <RouterLink to="/" onClick={onClose}>
                    <Icon
                      as={Logo}
                      fill={theme.colors.teal[500]}
                      height="65px"
                      width="100%"
                    />
                  </RouterLink>
                  <DrawerCloseButton position="relative" top={0} />
                </Flex>
              </DrawerHeader>
              <DrawerBody p={4}>
                <Flex direction="column" gap={6}>
                  {user ? (
                    <>
                      <RouterLink to={Routes.Account} onClick={onClose}>
                        <Button
                          width="100%"
                          justifyContent="flex-start"
                          variant="ghost"
                          leftIcon={<Headshot user={user} size="xs" />}
                        >
                          Account
                        </Button>
                      </RouterLink>
                      <RouterLink to={Routes.Membership} onClick={onClose}>
                        <Button
                          width="100%"
                          justifyContent="flex-start"
                          variant="ghost"
                          leftIcon={<FaCircleNotch size="1.5em" />}
                        >
                          Membership
                        </Button>
                      </RouterLink>
                      <RouterLink to={Routes.Personal} onClick={onClose}>
                        <Button
                          width="100%"
                          justifyContent="flex-start"
                          variant="ghost"
                          leftIcon={<FaLock size="1.5em" />}
                        >
                          Personal
                        </Button>
                      </RouterLink>
                      <RouterLink to="/chama" onClick={onClose}>
                        <Button
                          width="100%"
                          justifyContent="flex-start"
                          variant="ghost"
                          leftIcon={<FaCircleNotch size="1.5em" />}
                        >
                          Chama
                        </Button>
                      </RouterLink>
                      {BS_BLOG_URL && (
                        <Link href={BS_BLOG_URL} isExternal>
                          <Button
                            width="100%"
                            justifyContent="flex-start"
                            variant="ghost"
                            leftIcon={<FaBlog size="1.5em" />}
                          >
                            Blog
                          </Button>
                        </Link>
                      )}
                      <Button
                        width="100%"
                        justifyContent="flex-start"
                        variant="ghost"
                        leftIcon={<FaArrowRight size="1.5em" />}
                        onClick={() => {
                          onClose();
                          logout();
                        }}
                        color="red.500"
                      >
                        Logout
                      </Button>
                    </>
                  ) : (
                    <>
                      <RouterLink to="/auth" onClick={onClose}>
                        <Button
                          width="100%"
                          justifyContent="flex-start"
                          variant="ghost"
                          leftIcon={<FaUserLock size="1.5em" />}
                        >
                          Login / Signup
                        </Button>
                      </RouterLink>
                      {BS_BLOG_URL && (
                        <Link href={BS_BLOG_URL} isExternal>
                          <Button
                            width="100%"
                            justifyContent="flex-start"
                            variant="ghost"
                            leftIcon={<FaBlog size="1.5em" />}
                          >
                            Blog
                          </Button>
                        </Link>
                      )}
                    </>
                  )}
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      ) : (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          fontFamily="body"
          gap={4}
        >
          {BS_BLOG_URL && (
            <Link
              href={BS_BLOG_URL}
              isExternal
              borderRadius={"8px"}
              fontWeight={"700"}
              fontSize={{ base: "18px" }}
              minW={"124px"}
              height={"54px"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              _hover={{
                textDecor: "none",
                color: "teal",
              }}
            >
              BLOG
            </Link>
          )}
          {user ? (
            <>
              <RouterLink to="/chama">
                <Button
                  variant="ghost"
                  borderBottom={
                    location.pathname.startsWith("/chama")
                      ? `3px solid ${theme.colors.teal[500]}`
                      : "none"
                  }
                  colorScheme="teal"
                  minW={"124px"}
                  height={"54px"}
                >
                  CHAMA
                </Button>
              </RouterLink>
              <RouterLink to={Routes.Personal}>
                <Button
                  variant="ghost"
                  colorScheme="teal"
                  minW={"124px"}
                  height={"54px"}
                  borderBottom={
                    location.pathname.startsWith(Routes.Personal)
                      ? `3px solid ${theme.colors.teal[500]}`
                      : "none"
                  }
                >
                  PERSONAL
                </Button>
              </RouterLink>
              <RouterLink to={Routes.Membership}>
                <Button
                  variant="ghost"
                  borderBottom={
                    location.pathname.startsWith(Routes.Membership)
                      ? `3px solid ${theme.colors.teal[500]}`
                      : "none"
                  }
                  colorScheme="teal"
                  minW={"124px"}
                  height={"54px"}
                >
                  MEMBERSHIP
                </Button>
              </RouterLink>
              <Menu isLazy>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={
                    <Headshot
                      user={user}
                      size="md"
                      border={
                        location.pathname.startsWith(Routes.Account)
                          ? `3px solid ${theme.colors.teal[500]}`
                          : "none"
                      }
                    />
                  }
                  variant="none"
                />
                <MenuList w="100%" zIndex={100}>
                  <RouterLink to={Routes.Account}>
                    <MenuItem icon={<Headshot user={user} size="xs" />}>
                      Account
                    </MenuItem>
                  </RouterLink>
                  <MenuDivider />
                  <MenuItem
                    icon={<FaArrowRight size="1.5em" />}
                    onClick={logout}
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            </>
          ) : (
            <RouterLink to="/auth">
              <Button
                variant={"solid"}
                bgColor={"teal"}
                minW={"124px"}
                borderRadius={"8px"}
                height={"54px"}
                fontWeight={"700"}
                fontSize={{ base: "18px" }}
                border="2px solid teal"
                _hover={{
                  textDecor: "none",
                  bgColor: "white",
                  color: "teal",
                  border: "2px solid teal",
                }}
              >
                LOGIN / SIGNUP
              </Button>
            </RouterLink>
          )}
        </Flex>
      )}
    </Flex>
  );
});
